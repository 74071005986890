<template>
  <div v-loading="isLoading" class="page">
    <div class="border-radius4 height50" style="background: #FAFAFA;display: flex;">
      <div class="width110 font-size16 border-radius4004"
          style="background: #0768FD;color: white;display: flex; align-items: center;justify-content: center;cursor: pointer;"
           @click="goBack">
        返回
      </div>
      <div class="font-size16 margin-left30"
          style="color: black;font-weight: bold;display: flex; align-items: center;">
        车场详情
      </div>
    </div>
    <easy-card title="基本信息" class="border-radius6 padding18" style="background: white;">
      <el-descriptions :column="2" border class="margin-top">
        <el-descriptions-item
            label="车场名称"
            labelClassName="label-style"
        >
          {{ model.name }}
        </el-descriptions-item>
        <el-descriptions-item
            label="车场简称"
            labelClassName="label-style"
        >
          {{ model.shortName }}
        </el-descriptions-item>
        <el-descriptions-item label="城区" labelClassName="label-style">
          {{ model.regionName }}
        </el-descriptions-item>
        <el-descriptions-item
            label="停车场类型"
            labelClassName="label-style"
        >
          {{ model.parkingLotType === 1 ? "路内" : "路外" }}
        </el-descriptions-item>
        <el-descriptions-item
            label="车场经纬度"
            labelClassName="label-style"
        >
          {{ model.longitude + "," + model.latitude }}
        </el-descriptions-item>
        <el-descriptions-item
            label="详细地址"
            labelClassName="label-style"
        >
          {{ model.address }}
        </el-descriptions-item>
        <el-descriptions-item
            label="泊位个数（个）"
            labelClassName="label-style"
        >
          {{ model.parkingspaceNumber }}
        </el-descriptions-item>
        <el-descriptions-item
            label=""
            labelClassName="label-style"
        >
        </el-descriptions-item>
<!--        <el-descriptions-item-->
<!--            label="充电服务"-->
<!--            labelClassName="label-style"-->
<!--        >-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--            label="车场管理员"-->
<!--            labelClassName="label-style"-->
<!--        >-->
<!--          {{ model.linkMan }}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item-->
<!--            label="管理员联系方式"-->
<!--            labelClassName="label-style"-->
<!--        >-->
<!--          {{ model.linkTel }}-->
<!--        </el-descriptions-item>-->
        <el-descriptions-item
            label="车场平面图"
            labelClassName="label-style"
            span="3"
        >
          <div
              v-for="(item, index) in graphImages"
              :key="index"
              :src="item"
              class="width204 height180 margin-right10"
              style="border: 1px solid #ddd;display: inline-block;"
          >
            <img
                :src="item"
                object-fit="cover"
                style="width: 100%; height: 100%"
            />
          </div>
        </el-descriptions-item>
        <el-descriptions-item
            label="车场实景图"
            labelClassName="label-style"
            span="3"
        >
          <div
              v-for="(item, index) in realImages"
              :key="index"
              :src="item"
              class="width204 height180 margin-right10"
              style="border: 1px solid #ddd;display: inline-block;"
          >
            <img
                :src="item"
                object-fit="cover"
                style="width: 100%; height: 100%"
            />
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </easy-card>
<!--    <div class="tabs-wrap">-->
<!--      <el-tabs type="border-card">-->
<!--        <el-tab-pane label="基础信息">-->
<!--          <easy-card title="车场信息">-->
<!--            <el-descriptions :column="2" border class="margin-top">-->
<!--              <el-descriptions-item-->
<!--                  label="车场名称"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.name }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场简称"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.shortName }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item label="城区" labelClassName="label-style">-->
<!--                {{ model.regionName }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="停车场类型"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.parkingLotType === "1" ? "路内" : "路外" }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场经纬度"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.longitude + "," + model.latitude }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="详细地址"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.address }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="泊位个数（个）"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.parkingSpaceNumber }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="充电服务"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场管理员"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.linkMan }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="管理员联系方式"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.linkTel }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场平面图"-->
<!--                  labelClassName="label-style"-->
<!--                  span="3"-->
<!--              >-->
<!--                <div-->
<!--                    v-for="(item, index) in graphImages"-->
<!--                    :key="index"-->
<!--                    :src="item"-->
<!--                    style="-->
<!--                    width: 200px;-->
<!--                    height: 180px;-->
<!--                    border: 1px solid #ddd;-->
<!--                    display: inline-block;-->
<!--                    margin-right: 10px;-->
<!--                  "-->
<!--                >-->
<!--                  <img-->
<!--                      :src="item"-->
<!--                      object-fit="cover"-->
<!--                      style="width: 100%; height: 100%"-->
<!--                  />-->
<!--                </div>-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场实景图"-->
<!--                  labelClassName="label-style"-->
<!--                  span="3"-->
<!--              >-->
<!--                <div-->
<!--                    v-for="(item, index) in realImages"-->
<!--                    :key="index"-->
<!--                    :src="item"-->
<!--                    style="-->
<!--                    width: 200px;-->
<!--                    height: 180px;-->
<!--                    border: 1px solid #ddd;-->
<!--                    display: inline-block;-->
<!--                    margin-right: 10px;-->
<!--                  "-->
<!--                >-->
<!--                  <img-->
<!--                      :src="item"-->
<!--                      object-fit="cover"-->
<!--                      style="width: 100%; height: 100%"-->
<!--                  />-->
<!--                </div>-->
<!--              </el-descriptions-item>-->
<!--            </el-descriptions>-->
<!--          </easy-card>-->
<!--          <easy-card title="企业信息">-->
<!--            <el-descriptions :column="2" border class="margin-top">-->
<!--              <el-descriptions-item-->
<!--                  label="结算方信息"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.settlementName }}-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="父级车场"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ model.parentName }}-->
<!--              </el-descriptions-item>-->
<!--            </el-descriptions>-->
<!--          </easy-card>-->
<!--        </el-tab-pane>-->
<!--        &lt;!&ndash; 分润规则详情 &ndash;&gt;-->
<!--        <el-tab-pane label="分润规则">-->
<!--          &lt;!&ndash; <div class="share-rules">敬请期待</div> &ndash;&gt;-->
<!--          <easy-card title="运营信息">-->
<!--            <el-form-->
<!--                ref="model"-->
<!--                :model="shareModel"-->
<!--                label-width="120px"-->
<!--                size="small"-->
<!--            >-->
<!--              <el-row>-->
<!--                <el-col :span="12">-->
<!--                  <el-form-item-->
<!--                      label="结算时间"-->
<!--                      prop="settlementDateType"-->
<!--                      required-->
<!--                  >-->
<!--                    <el-select-->
<!--                        v-model="shareModel.settlementDateType"-->
<!--                        disabled-->
<!--                        placeholder="结算时间"-->
<!--                    >-->
<!--                      <el-option label="及时" value="IN_TIME"></el-option>-->
<!--                      <el-option label="日" value="DAY"></el-option>-->
<!--                      <el-option label="周" value="WEEK"></el-option>-->
<!--                      <el-option label="月" value="MONTH"></el-option>-->
<!--                      <el-option label="季度" value="SEASON"></el-option>-->
<!--                      <el-option label="年" value="YEAR"></el-option>-->
<!--                    </el-select>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </el-form>-->
<!--          </easy-card>-->

<!--          <easy-card title="停车业务分润">-->
<!--            <el-table-->
<!--                :data="parkingShareList"-->
<!--                class="common-table"-->
<!--                style="width: 100%"-->
<!--            >-->
<!--              <el-table-column-->
<!--                  label="结算方名称"-->
<!--                  prop="settlementName"-->
<!--                  width="350"-->
<!--              >-->
<!--              </el-table-column>-->
<!--              &lt;!&ndash; <el-table-column label="账期（天）" width="180"> </el-table-column> &ndash;&gt;-->
<!--              <el-table-column label="分润比例(%)" prop="amount" width="200">-->
<!--              </el-table-column>-->
<!--              <el-table-column label="说明" prop="description">-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--          </easy-card>-->

<!--          <easy-card title="长租业务分润">-->
<!--            <el-table-->
<!--                :data="leaseShareList"-->
<!--                class="common-table"-->
<!--                style="width: 100%"-->
<!--            >-->
<!--              <el-table-column-->
<!--                  label="结算方名称"-->
<!--                  prop="settlementName"-->
<!--                  width="350"-->
<!--              >-->
<!--              </el-table-column>-->
<!--              &lt;!&ndash; <el-table-column label="账期（天）" width="180"> </el-table-column> &ndash;&gt;-->
<!--              <el-table-column label="分润比例(%)" prop="amount" width="200">-->
<!--              </el-table-column>-->
<!--              <el-table-column label="说明" prop="description">-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--          </easy-card>-->
<!--        </el-tab-pane>-->

<!--        &lt;!&ndash; 运营规则详情 &ndash;&gt;-->
<!--        <el-tab-pane label="运营规则">-->
<!--          <easy-card title="运营信息">-->
<!--            <el-descriptions :column="2" border class="margin-top">-->
<!--              <el-descriptions-item-->
<!--                  label="泊位监控"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ parkingSpaceMonitorFormat(model.isMonitoringParking) }}-->
<!--                &lt;!&ndash; model.isMonitoringParking === "1" ? "可监控" : "不可监控" &ndash;&gt;-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="线上停车订单"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ onLineParkingOrderFormat(model.onlineParkingOrder) }}-->
<!--                &lt;!&ndash; model.isMonitoringParking === "1" ? "运营" : "仅数据" &ndash;&gt;-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="订单预支付"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ orderAdvancePaymentFormat(model.orderAdvancePayment) }}-->
<!--                &lt;!&ndash; model.orderAdvancePayment === "1" ? "预支付渠道" : "充值渠道" &ndash;&gt;-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="收费资质"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ chargingQualificationFormat(model.chargingQualification) }}-->
<!--                &lt;!&ndash; model.chargingQualification === "1" ? "平台收费" : "独立收费" &ndash;&gt;-->
<!--              </el-descriptions-item>-->
<!--              &lt;!&ndash; <el-descriptions-item-->
<!--                label="清欠范围"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                label="用户清欠范围"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                label="中止计费"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                label="违停监控"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                label="违停审核"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                label="审核场景"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                label="巡检图片"-->
<!--                labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item> &ndash;&gt;-->
<!--              <el-descriptions-item-->
<!--                  label="ETC结算平台"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--                {{ etcConfig[model.etcSettlementPlatform] }}-->
<!--              </el-descriptions-item>-->
<!--            </el-descriptions>-->
<!--          </easy-card>-->
<!--        </el-tab-pane>-->
<!--        <el-tab-pane label="计费规则">-->
<!--          <easy-card title="计费规则">-->
<!--            <el-table-->
<!--                v-loading="isLoading"-->
<!--                :data="priceRuleList"-->
<!--                border-->
<!--                class="common-table"-->
<!--                size="medium"-->
<!--                style="width: 100%"-->
<!--            >-->
<!--              <el-table-column label="计费策略">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{-->
<!--                      scope.row.calcType === 0 ? "默认计费" : "特殊计费"-->
<!--                    }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="生效时间" width="340">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{ scope.row.activateTime }}</span>-->
<!--                  <span v-if="scope.row.activateStatus === 2"-->
<!--                  >~{{ scope.row.activateEnd }}</span-->
<!--                  >-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="计费描述">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{ scope.row.remark }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column label="创建时间">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{ scope.row.createTime }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              &lt;!&ndash; <el-table-column label="创建人"> </el-table-column> &ndash;&gt;-->
<!--              <el-table-column label="状态">-->
<!--                <template slot-scope="scope">-->
<!--                  <span>{{-->
<!--                      scope.row.activateStatus === 0-->
<!--                          ? "待生效"-->
<!--                          : scope.row.activateStatus === 1-->
<!--                              ? "已生效"-->
<!--                              : "已失效"-->
<!--                    }}</span>-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--              <el-table-column align="center" label="操作" width="340">-->
<!--                <template slot-scope="scope">-->
<!--                  <el-button-->
<!--                      class="common-text"-->
<!--                      size="small"-->
<!--                      type="text"-->
<!--                      @click="-->
<!--                      (detailDialogFormVisible = true),-->
<!--                        goPriceRuleDetail(scope.row.id)-->
<!--                    "-->
<!--                  >查看-->
<!--                  </el-button-->
<!--                  >-->
<!--                </template>-->
<!--              </el-table-column>-->
<!--            </el-table>-->
<!--          </easy-card>-->

<!--          &lt;!&ndash; 查看计费规则详情 &ndash;&gt;-->
<!--          <el-dialog-->
<!--              :append-to-body="true"-->
<!--              :visible.sync="detailDialogFormVisible"-->
<!--              class="common-dialog"-->
<!--              title="查看计费"-->
<!--          >-->
<!--            <div>-->
<!--              <easy-card class="box" title="免费时长">-->
<!--                <p v-if="freeTimeTable.length > 0" class="remarkTip">-->
<!--                  减免方式：{{-->
<!--                    freeTimeTable[0].reductionType === 0-->
<!--                        ? "按订单时段减免"-->
<!--                        : "按计费时段减免"-->
<!--                  }}/{{-->
<!--                    freeTimeTable[0].calcTimeFree === 0-->
<!--                        ? "超时计入"-->
<!--                        : "超时不计入/"-->
<!--                  }}-->
<!--                  <span v-if="freeTimeTable[0].calcTimeFree === 1">{{-->
<!--                      freeTimeTable.freeType === 0 ? "前免费" : "后免费"-->
<!--                    }}</span>-->
<!--                </p>-->
<!--                <el-table-->
<!--                    :data="freeTimeTable"-->
<!--                    border-->
<!--                    class="common-table"-->
<!--                    size="medium"-->
<!--                    style="width: 100%"-->
<!--                >-->
<!--                  <el-table-column label="生效车牌" prop="plateType">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{ formatNumplate(scope.row.plateType) }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="生效周期" prop="activePeriod">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{-->
<!--                          scope.row.activePeriod === "0"-->
<!--                              ? "不限"-->
<!--                              : scope.row.activePeriod === "1"-->
<!--                                  ? "工作日"-->
<!--                                  : "休息日"-->
<!--                        }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="免费时长" prop="freeTime">-->
<!--                    <template slot-scope="scope">-->
<!--                      {{ scope.row.freeTime }}分钟-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                </el-table>-->
<!--              </easy-card>-->
<!--            </div>-->

<!--            <div>-->
<!--              <easy-card class="box" title="封顶金额">-->
<!--                <p v-if="topRuleTable.length > 0" class="remarkTip">-->
<!--                  减免方式：{{-->
<!--                    topRuleTable.limitType === 0-->
<!--                        ? "按自然日"-->
<!--                        : topRuleTable.limitType === 1-->
<!--                            ? "按24小时"-->
<!--                            : "按整笔订单"-->
<!--                  }}-->
<!--                </p>-->
<!--                <el-table-->
<!--                    :data="topRuleTable"-->
<!--                    border-->
<!--                    class="common-table"-->
<!--                    size="medium"-->
<!--                    style="width: 100%"-->
<!--                >-->
<!--                  <el-table-column label="生效车牌" prop="plateType">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{ formatNumplate(scope.row.plateType) }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="生效周期" prop="activePeriod">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{-->
<!--                          scope.row.activePeriod === 0 ? "不限" : ""-->
<!--                        }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="时间段" prop="activeType">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{-->
<!--                          scope.row.activeType === 0 ? "全天" : ""-->
<!--                        }}</span>-->
<!--                      &lt;!&ndash; <span v-if="scope.row.activeType === '1'">{{scope.row.activeTimeBegin}}~{{scope.row.activeTimeEnd}}</span> &ndash;&gt;-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="封顶金额(元)" prop="maxLimit">-->
<!--                  </el-table-column>-->
<!--                </el-table>-->
<!--              </easy-card>-->
<!--            </div>-->

<!--            <div>-->
<!--              <easy-card class="box" title="计费规则">-->
<!--                <el-table-->
<!--                    :data="priceRuleTable"-->
<!--                    border-->
<!--                    class="common-table"-->
<!--                    size="medium"-->
<!--                    style="width: 100%"-->
<!--                >-->
<!--                  <el-table-column label="生效车牌" prop="plateType">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{ formatNumplate(scope.row.plateType) }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="生效周期" prop="activeTimeType">-->
<!--                    <template slot-scope="scope">-->
<!--                      {{-->
<!--                        scope.row.activeTimeType === 0-->
<!--                            ? "不限"-->
<!--                            : scope.row.activeTimeType === 1-->
<!--                                ? "工作日"-->
<!--                                : "休息日"-->
<!--                      }}-->
<!--                      <span v-if="scope.row.activeLegalHolidays === 0"-->
<!--                      >(启用法定节假日)</span-->
<!--                      >-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column label="时间段" prop="effectualTime">-->
<!--                    <template slot-scope="scope">-->
<!--                      {{-->
<!--                        scope.row.activeTimeScope === "00:00:00-23:59:59"-->
<!--                            ? "全天"-->
<!--                            : ""-->
<!--                      }}-->
<!--                      <span-->
<!--                          v-if="scope.row.activeTimeScope !== '00:00:00-23:59:59'"-->
<!--                      >{{ scope.row.activeTimeScope }}</span-->
<!--                      >-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                  <el-table-column-->
<!--                      label="计费方式"-->
<!--                      prop="contactNumber"-->
<!--                      width="250"-->
<!--                  >-->
<!--                    <template slot-scope="scope">-->
<!--                      <span v-if="scope.row.ruleType === 0">-->
<!--                        {{ scope.row.price }}元/{{-->
<!--                          scope.row.calcUnit-->
<!--                        }}分钟/不足单位时长{{-->
<!--                          scope.row.notEnoughUnit === 0 ? "收费" : "免费"-->
<!--                        }}/切换计费时段{{-->
<!--                          scope.row.unitChange === 0-->
<!--                              ? "开始新单位时长"-->
<!--                              : "继续当前单位时长"-->
<!--                        }}-->
<!--                      </span>-->
<!--                      <span v-if="scope.row.ruleType === 1">-->
<!--                        {{ scope.row.price }}元/次<span-->
<!--                          v-if="scope.row.freeTime !== ''"-->
<!--                      >,按次免费时长{{ scope.row.freeTime }}分钟</span-->
<!--                      >-->
<!--                      </span>-->
<!--                      <span v-if="scope.row.ruleType === 2">-->
<!--                        {{ formatLadderList(scope.row.calcDetailList) }}-->
<!--                        <div-->
<!--                            v-for="item in scope.row.calcDetailList"-->
<!--                            :key="item.text"-->
<!--                        >-->
<!--                          {{ item.text }}-->
<!--                        </div>-->
<!--                      </span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                </el-table>-->
<!--              </easy-card>-->
<!--            </div>-->

<!--            <div>-->
<!--              <easy-card class="box" title="预支付时长">-->
<!--                <el-table-->
<!--                    :data="paymentTimeTable"-->
<!--                    border-->
<!--                    class="common-table"-->
<!--                    size="medium"-->
<!--                    style="width: 100%"-->
<!--                >-->
<!--                  <el-table-column label="预支付时长(分钟)" prop="prePayTime">-->
<!--                    <template slot-scope="scope">-->
<!--                      <span>{{ scope.row.prePayTime }}</span>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
<!--                </el-table>-->
<!--              </easy-card>-->
<!--            </div>-->

<!--            <div slot="footer" class="dialog-footer">-->
<!--              <el-button type="primary" @click="closeDetail">关闭</el-button>-->
<!--            </div>-->
<!--          </el-dialog>-->
<!--        </el-tab-pane>-->
<!--        <el-tab-pane label="扩展信息">-->
<!--          <easy-card title="扩展信息">-->
<!--            <el-descriptions :column="2" border class="margin-top">-->
<!--              <el-descriptions-item-->
<!--                  label="产权单位"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="产权方联系人"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="产权方电话"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场属性"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="建筑属性"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="建设时间"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="使用年限（年）"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="违停监控"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--              <el-descriptions-item-->
<!--                  label="车场描述"-->
<!--                  labelClassName="label-style"-->
<!--              >-->
<!--              </el-descriptions-item>-->
<!--            </el-descriptions>-->
<!--          </easy-card>-->
<!--        </el-tab-pane>-->
<!--      </el-tabs>-->
<!--      &lt;!&ndash; <el-button type="text" class="back-button" @click="goBack"-->
<!--        ><i class="el-icon-back"></i>上一页</el-button-->
<!--      > &ndash;&gt;-->
<!--    </div>-->
  </div>
</template>

<script>
import {
  getParkingDetail,
  getParkingImage,
  getSettlementConfigByObjectId,
} from "@/api/parking";
import {getCalcDetailsByCalcTypeId, getCalcTypePage} from "@/api/price";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  name: "parking-detail",
  data() {
    return {
      isLoading: false,
      parkingId: "",
      model: {
        name: "",
        shortName: "",
        regionName: "",
        longitude: 0.0,
        latitude: 0.0,
        address: "",
        parkingSpaceNumber: 0,
        parkingType: "",
        linkMan: "",
        linkTel: "",
      },
      pageNo: 1,
      pageSize: 15,
      priceRuleList: [], //计费规则，停车业务列表
      //查看计费规则详情
      freeTimeTable: [], //免费时长表格
      topRuleTable: [], //封顶金额表格
      priceRuleTable: [], //计费规则表格
      paymentTimeTable: [], //预支付时长表格
      detailDialogFormVisible: false, //查看计费规则详情弹出窗
      // 平面图
      graphImages: [],
      // 实景图
      realImages: [],
      etcConfig: {
        1: "ETC",
        2: "ETC-SD",
        3: "ETC-QT",
        4: "ETC-ZG",
        0: "无",
      },
      //分润规则--运营信息
      shareModel: {
        settlementDateType: "",
      },
      parkingShareList: [], //停车业务分润
      leaseShareList: [], //长租业务分润
    };
  },
  methods: {
    parkingSpaceMonitorFormat(str) {
      if (!str || str === 0) {
        return "";
      }

      return str === 1 ? "可监控" : "不可监控";
    },
    onLineParkingOrderFormat(str) {
      if (!str) {
        return "";
      }
      return str === "1" ? "运营" : "仅数据";
    },
    orderAdvancePaymentFormat(str) {
      if (!str) {
        return "";
      }
      return str === "1" ? "预支付渠道" : "充值渠道";
    },
    chargingQualificationFormat(str) {
      if (!str) {
        return "";
      }
      return str === 1 ? "平台收费" : "独立收费";
    },
    async getParkingDetail() {
      this.isLoading = true;
      const res = await getParkingDetail(this.parkingId);
      this.isLoading = false;

      if (res && res.code === 30 && res.result) {
        this.model = res.returnObject;

        this.getAllGraphImages(
            [res.returnObject.plandefaultAttachmentId]
        );

        this.getAllRealImages(
            [res.returnObject.sceneDefaultAttachmentId]
        );
      }
    },
    goBack() {
      var item =  {
        "fullPath": "/parking_manage/detail?parkingId=69a6bf23f5c04ee6af0465222f4f8d46",
        "hash": "",
        "meta": {
          "requiresAuth": true,
          "isAdmin": true,
          "icon": "el-icon-bell",
          "hidden": true,
          "title": "停车场详情"
        },
        "name": "parking_detail",
        "params": {},
        "path": "/parking_manage/detail",
        "query": {
          "parkingId": "69a6bf23f5c04ee6af0465222f4f8d46"
        }
      };
      this.$store.commit("app/removeRoute", item);
      this.$router.back();
    },
    // 批量获取平面图列表
    getAllGraphImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getParkingImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.graphImages = res;
      });
    },
    // 获取车场实景图列表
    getAllRealImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getParkingImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.realImages = res;
      });
    },
    async getParkingImage(attachmentId) {
      const res = await getParkingImage(attachmentId);
      if (res && res.code === 30 && res.result) {
        return res.returnObject;
      }
    },
    //查询计费规则列表
    goSearch() {
      const pageNo = this.pageNo;
      const pageSize = this.pageSize;
      const parkId = this.parkingId;
      this.isLoading = true;
      getCalcTypePage(pageNo, parkId, pageSize).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.priceRuleList = res.returnObject.list;
        }
      });
    },
    //查询计费规则详情
    goPriceRuleDetail(item) {
      getCalcDetailsByCalcTypeId(item).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.freeTimeTable = res.returnObject.calcFreeList;
          this.topRuleTable = res.returnObject.calcMaxLimitList;
          this.priceRuleTable = res.returnObject.calcList;
          this.paymentTimeTable.push(res.returnObject.calcPrepay);
        }
      });
    },
    //处理车牌
    formatNumplate(items) {
      items = typeof items === "string" ? items.split(",") : items;
      return items
          .map((item) => {
            const map = {
              0: "通用",
              "01": "黄牌",
              "02": "蓝牌",
              "06": "黑牌",
              "03": "新能源",
            };
            return map[item];
          })
          .join(",");
    },
    //处理阶梯计费
    formatLadderList(items) {
      items.map((item) => {
        item.text = `${item.calcStart}~${item.calcEnd}分钟, ${item.price}元/${item.calcUnit}分钟`;
        if (item.calcEnd === 99999) {
          item.text = `剩余时长，${item.price}元/${item.calcUnit}分钟`;
        }
      });
    },
    //关闭查看计费窗口
    closeDetail() {
      this.detailDialogFormVisible = false;
    },
    //查询分润规则详情
    getShareDetail() {
      getSettlementConfigByObjectId(this.parkingId).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.shareModel.settlementDateType =
              res.returnObject.settlementDateType;
          this.parkingShareList = res.returnObject.parking;
          this.leaseShareList = res.returnObject.parkingLotLease;
        }
      });
    },
  },
  mounted() {
    checkToken()
        .then(res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            this.parkingId = this.$route.query.parkingId;
            this.getParkingDetail();
            this.goSearch();
            this.getShareDetail();
          }
        })
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}

.share-rules {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #999;
}
</style>
